import { default as ai_45form_45builder7WzRJA0Du7Meta } from "/app/pages/ai-form-builder.vue?macro=true";
import { default as editwQVCmQcpMNMeta } from "/app/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexLta7GnB5koMeta } from "/app/pages/forms/[slug]/index.vue?macro=true";
import { default as indexiAwFCXujVZMeta } from "/app/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexsU1Mw3mQl0Meta } from "/app/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareMU8dKw0g4EMeta } from "/app/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsZfUFjbagddMeta } from "/app/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionseBMBCJMLo9Meta } from "/app/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as shownMX8mEbjaWMeta } from "/app/pages/forms/[slug]/show.vue?macro=true";
import { default as guestnNfDCKGrpJMeta } from "/app/pages/forms/create/guest.vue?macro=true";
import { default as index9L3mF65oE2Meta } from "/app/pages/forms/create/index.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as callbackU6TpRqsK7GMeta } from "/app/pages/oauth/callback.vue?macro=true";
import { default as emailCBPRBFsa6RMeta } from "/app/pages/password/email.vue?macro=true";
import { default as _91token_93aZSSnxQtLOMeta } from "/app/pages/password/reset/[token].vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as access_45tokensN701OIvU2HMeta } from "/app/pages/settings/access-tokens.vue?macro=true";
import { default as accountFbmhd9M064Meta } from "/app/pages/settings/account.vue?macro=true";
import { default as adminsNxuUjdaTvMeta } from "/app/pages/settings/admin.vue?macro=true";
import { default as billingu0Xntxr74XMeta } from "/app/pages/settings/billing.vue?macro=true";
import { default as connectionsl9rSw6yTJwMeta } from "/app/pages/settings/connections.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as passwordZQhuzffxi0Meta } from "/app/pages/settings/password.vue?macro=true";
import { default as profile8S52gQnHEjMeta } from "/app/pages/settings/profile.vue?macro=true";
import { default as workspace5sI0HuMVKVMeta } from "/app/pages/settings/workspace.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as erroremsGw7wmnJMeta } from "/app/pages/subscriptions/error.vue?macro=true";
import { default as successrVysLx7SdsMeta } from "/app/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93LAzaU3fYJ8Meta } from "/app/pages/templates/[slug].vue?macro=true";
import { default as index6xet4YTGVdMeta } from "/app/pages/templates/index.vue?macro=true";
import { default as _91slug_93ud615Anym0Meta } from "/app/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesbQ693vj68bMeta } from "/app/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93Zaiz6yTLiTMeta } from "/app/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsRRu4oncQRtMeta } from "/app/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsxtaYpxcbsRMeta } from "/app/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builder7WzRJA0Du7Meta || {},
    component: () => import("/app/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editwQVCmQcpMNMeta || {},
    component: () => import("/app/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/app/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: shownMX8mEbjaWMeta?.name,
    path: "/forms/:slug()/show",
    meta: shownMX8mEbjaWMeta || {},
    component: () => import("/app/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexiAwFCXujVZMeta?.redirect,
    component: () => import("/app/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexsU1Mw3mQl0Meta || {},
    component: () => import("/app/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareMU8dKw0g4EMeta || {},
    component: () => import("/app/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsZfUFjbagddMeta || {},
    component: () => import("/app/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionseBMBCJMLo9Meta || {},
    component: () => import("/app/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestnNfDCKGrpJMeta || {},
    component: () => import("/app/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: index9L3mF65oE2Meta || {},
    component: () => import("/app/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeH3NsyxtJwrMeta || {},
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailCBPRBFsa6RMeta || {},
    component: () => import("/app/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93aZSSnxQtLOMeta || {},
    component: () => import("/app/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registermu8R7rSP5uMeta || {},
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name,
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/app/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountFbmhd9M064Meta || {},
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminsNxuUjdaTvMeta || {},
    component: () => import("/app/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingu0Xntxr74XMeta || {},
    component: () => import("/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsl9rSw6yTJwMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/app/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: index11eXGkSJBcMeta?.redirect,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordZQhuzffxi0Meta || {},
    component: () => import("/app/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profile8S52gQnHEjMeta || {},
    component: () => import("/app/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspace5sI0HuMVKVMeta || {},
    component: () => import("/app/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: erroremsGw7wmnJMeta || {},
    component: () => import("/app/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successrVysLx7SdsMeta || {},
    component: () => import("/app/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/app/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/app/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesbQ693vj68bMeta || {},
    component: () => import("/app/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/app/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsRRu4oncQRtMeta || {},
    component: () => import("/app/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/app/pages/update-credentials.vue").then(m => m.default || m)
  }
]